import Swal from 'sweetalert2/dist/sweetalert2.js';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-center',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

const ToastWarning = Swal.mixin({
  customClass: {
    container: 'csa-warning',
  },
  toast: true,
  position: 'top',
  showConfirmButton: false,
  showCloseButton: true,
  showClass: {
    popup: `
      animated fadeInDown
    `
  },
  hideClass: {
    popup: `
      animated fadeOutUp
    `
  }
});

const AgreeTerms = Swal.mixin({
  customClass: {
    container: 'csa-agree-terms',
  },
  showCloseButton: true,
  width: 600,
  input: 'checkbox',
  inputPlaceholder: '我已閱讀並同意條款',
  confirmButtonText: '同意',
  inputValidator: (result) => {
    return !result && '請閱讀並同意條款'
  }
});

const CheckOrder = Swal.mixin({
  customClass: {
    container: 'csa-check-order',
  },
  showCloseButton: true,
  width: 600,
  showCancelButton: true,
  cancelButtonText: '修改',
  confirmButtonText: '確認',
});


window.Swal = Swal;
window.Toast = Toast;
window.ToastWarning = ToastWarning;
window.AgreeTerms = AgreeTerms;
window.CheckOrder = CheckOrder;
