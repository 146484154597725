// import 'bootstrap'
import '@nathanvda/cocoon'
import 'core-js'
import Cookies from 'js-cookie'
import AOS from 'aos';
import 'public/lion/js/flatpickr';
import 'public/lion/js/custom_alert.js'
import 'devbridge-autocomplete'

window.Cookies = Cookies;


// JavaScript
$(document).ready(function () {
  isIE();

  $('#mobileMenuBtn').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu').toggleClass('active');
    $('#header').toggleClass('active');
  });

  $('#userBtn, .user-mask, .menu-close').on('click', function () {
    $('#userBtn').toggleClass('active');
    $('.header-user-menu').toggleClass('active');
    $('.user-mask').toggleClass('active');
  });

  $(".header-menu-item.group-link").on("click", function () {
    $(this).toggleClass('is-active');
  });
  $('.header-menu-nav').find('a').on('click', function () {
    $('#mobileMenuBtn').toggleClass('active');
    $('.header-menu').removeClass('active');
    $('#header').removeClass('active');
  });


  $(".back-to-top").on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 300, 'swing');
  });
  var window_width = $(window).width();
  var scroll_change = 20;
  if (window_width > 900) {
    var scroll_change = 130;
  }
  if ($(window).scrollTop() > scroll_change) {
    $('.header').addClass("rwd-header");
    $(".back-to-top").fadeIn();
  } else {
    $('.header').removeClass("rwd-header");
    $(".back-to-top").fadeOut();
  }

  $(window).on('scroll', function () {
    // transparent header
    if ($(window).scrollTop() > scroll_change) {
      $('.header').addClass("rwd-header");
      $(".back-to-top").fadeIn();
    } else {
      $('.header').removeClass("rwd-header");
      $(".back-to-top").fadeOut();
    }
  });

  //shipping and faqs accordion
  $('.faqs .faqs-accordion h3').click(function(){
      $(this).parent('.item').toggleClass('active').siblings('.item').removeClass('active').find('.content').slideUp(400);
      $(this).siblings('.content').slideToggle(400);
  });

  // file upload
  $('.upload-file').on('change', function() {
    let thisReferesTo = $(this).val().replace(/C:\\fakepath\\/i, '');
    $(this).siblings('.file-status').find('.file-name').html(thisReferesTo);
  })

  $('.file-reset').on('click', function(e) {
    e.preventDefault();
    $(this).parent('.upload-file').val('');
    $(this).siblings('.file-name').html('請選擇檔案');
  });

  $('.tab[data-tab]').on('click', function () {
    var tabPanes = $(this).data('tabs');
    var ID = $(this).data('tab');
    $(this).addClass('is-active').siblings().removeClass('is-active');
    $("[data-tab-panes=" + tabPanes + "]").find("[data-tab-pane=" + ID + "]").addClass('is-active').siblings().removeClass('is-active');
  });
});

window.addEventListener('load', (event) => {
  AOS.init({
    disable: false,
    startEvent: 'DOMContentLoaded',
    initClassName: 'aos-init',
    animatedClassName: 'aos-animate',
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,
    offset: 120,
    delay: 0,
    duration: 400,
    easing: 'ease',
    once: true,
    mirror: false,
    anchorPlacement: 'top-bottom',
  });
});


function isIE() { //ie?
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    $("body").addClass('isIE');
  } else {
    $("body").removeClass('isIE');
  }
}
