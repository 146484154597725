import 'public/lion/style.scss'
import 'public/lion/main'
import 'public/lion/js/notification'
import TwCitySelector from 'tw-city-selector'
import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
window.TwCitySelector = TwCitySelector;
