import flatpickr from 'flatpickr';
import FlatpickrLanguages from 'flatpickr/dist/l10n';

window.FlatpickrLangTW = FlatpickrLanguages.zh_tw

function initFlatpickr() {
  const d = new Date();
  let hour = d.getHours();
  let startDay = (hour >= 15 ? d.fp_incr(1) : 'today');
  flatpickr('.datepicker', {
    locale: { ...FlatpickrLangTW },
    format: 'LT',
    minDate: startDay,
  });
  
  flatpickr('.datepicker-no-sunday', {
    locale: { ...FlatpickrLangTW },
    format: 'LT',
    minDate: startDay,
    disable: [
      function(date) {
        return (date.getDay() === 0);
      }
    ],
  });
}


$(document).ready(() => {
  initFlatpickr();
});
